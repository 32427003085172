import styled from 'styled-components';

const Table = styled.table`
  width: 100%;
  font-family: 'Roboto', sans-serif;
  font-weight: 300;
`;

const Head = styled.thead``;
const Body = styled.tbody``;
const Row = styled.tr`
  height: 32px;
`;

const BodyRow = styled(Row)<{ highlight?: boolean; unverified?: boolean }>`
  color: ${({ unverified }) => (unverified ? '#999' : '#333')};
  background: ${({ highlight }) => (highlight ? '#ddddff' : 'transparent')};
  &:hover {
    background: ${({ highlight }) => (highlight ? '#ddddff' : '#eee')};
  }
`;
const Header = styled.th`
  text-align: center;
  font-weight: 400;
`;
const Data = styled.td`
  padding: 4px 8px;
  text-align: center;
`;

const MobileHiddenHeader = styled(Header)<{ forceVisible?: boolean }>`
  @media (max-width: 640px) {
    display: ${({ forceVisible }) => (forceVisible ? 'table-cell' : 'none')};
  }
`;

const MobileHiddenData = styled(Data)<{ forceVisible?: boolean }>`
  @media (max-width: 640px) {
    display: ${({ forceVisible }) => (forceVisible ? 'table-cell' : 'none')};
  }
`;

type CompoundTableType = typeof Table & {
  Head: typeof Head;
  Body: typeof Body;
  Row: typeof Row;
  BodyRow: typeof BodyRow;
  Header: typeof Header;
  Data: typeof Data;
  MobileHiddenHeader: typeof MobileHiddenHeader;
  MobileHiddenData: typeof MobileHiddenData;
};

const CompoundTable: CompoundTableType = Table as any;
CompoundTable.Head = Head;
CompoundTable.Body = Body;
CompoundTable.Row = Row;
CompoundTable.BodyRow = BodyRow;
CompoundTable.Header = Header;
CompoundTable.Data = Data;
CompoundTable.MobileHiddenHeader = MobileHiddenHeader;
CompoundTable.MobileHiddenData = MobileHiddenData;

export default CompoundTable;
